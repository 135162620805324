import React from "react";
import SEO from "../components/seo";
import Layout from "../containers/layout/layout";
import Banner from '../containers/company/banner';
import Sitemap from '../containers/sitemap';

export default () => (
  <Layout>
    <SEO title="Sitemap" />
    <Banner/>
    <Sitemap />
  </Layout>
);