import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import SectionTitle from '../../components/shared/section-title'
import {SitemapSectionWrap} from './sitemap.stc';

const SitemapSection = ({section, project}) => {
    const mapQueryData = useStaticQuery(graphql `
    query MapPageDataQuery {
        homedataJson(id: {eq: "sitemap_section_content"}) {
            id
            title
            subtitle
        }
    }  
    `);
    const mapSecData = mapQueryData.homedataJson;
    return (
        <SitemapSectionWrap {...section}>
            <div className="project-container">
                <div className="col-1 offset-1">
                    <SectionTitle
                        title={mapSecData.title}
                        subtitle={mapSecData.subtitle}
                    />
                </div>
 
                <div className="project-wrapper">
                    
                </div>
            </div>
        </SitemapSectionWrap>
    )
}

SitemapSection.propTypes = {
    section: PropTypes.object,
    project: PropTypes.object
}

SitemapSection.defaultProps = {
    section: {
        backgroundColor: '#f8f8f8'
    },
}

export default SitemapSection;
